.navbar-default#pages {
    background-color: #273140;
}

.kb-box-single-page {
    margin-left: 10px;
}

.kb-box-single-page span {
    color: #A9A9A9;
    padding-left: 2px;
    padding-right: 20px;
    font-size: 13px;
}

.margin-top-100 {
    padding-top: 100px;
}

.content {
    margin-bottom: 25px;
}

.content > div > h3 {
    margin-top: 0;
}

.entry-content {
    margin: 0;
    padding: 0 0 20px;
    line-height: 25px;
}

.submenu a {
    padding: 10px 18px 6px !important;
    display: block;
}