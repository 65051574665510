@charset "UTF-8";
.schema_seo {
  position: absolute;
  color: transparent; }

/* About us */
.about-page .banner-content {
  min-height: 300px;
  position: relative; }
  .about-page .banner-content img {
    max-width: 100%;
    position: relative;
    top: -9vw;
    width: 100%; }
  .about-page .banner-content .container {
    position: absolute;
    top: 25%;
    width: 100%;
    text-align: center;
    color: #FFF; }
    .about-page .banner-content .container h3 {
      font-family: WorkSans;
      font-size: 40px;
      font-weight: 200;
      letter-spacing: -1px; }
    .about-page .banner-content .container small {
      padding-top: 20px;
      font-family: PTSans;
      font-size: 25px;
      font-weight: 200;
      display: block;
      color: #FFF; }

.about-page .info-about-us:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 100%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 80px 100vw 0 0;
  border-color: transparent #fff transparent transparent;
  border-width: 180px 100vw 0 0; }

.about-page .info-about-us {
  background-color: #fff;
  margin-top: -17vw;
  text-align: center;
  padding-bottom: 15px;
  position: relative;
  z-index: 99; }
  .about-page .info-about-us h2 {
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 10px; }
  .about-page .info-about-us p {
    max-width: 600px;
    margin: 0 auto;
    font-size: 18px;
    line-height: 30px;
    padding-bottom: 32px; }

.about-page .info-why .container,
.about-page .info-get-today .container,
.about-page .info-dashboard .container,
.about-page .employees .container {
  padding: 70px 0px; }

.about-page .info-why .section-heading,
.about-page .info-get-today .section-heading,
.about-page .info-dashboard .section-heading,
.about-page .employees .section-heading {
  text-align: center; }

.about-page .info-why .separator,
.about-page .info-get-today .separator,
.about-page .info-dashboard .separator,
.about-page .employees .separator {
  margin-bottom: 25px; }

.about-page .info-why h2,
.about-page .info-get-today h2,
.about-page .info-dashboard h2,
.about-page .employees h2 {
  margin-top: 0;
  margin-bottom: 18px;
  font-size: 30px;
  letter-spacing: -1px;
  line-height: 40px; }

.about-page .info-why p,
.about-page .info-get-today p,
.about-page .info-dashboard p,
.about-page .employees p {
  color: #666;
  font-family: PTSans;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px; }

.about-page .info-why, .about-page .employees {
  background: #F8F8F8; }

.about-page .location .container {
  padding: 35px 0; }

.about-page .location p {
  margin-bottom: 15px; }

.about-page .info-get-today {
  background: #F8F8F8; }
  .about-page .info-get-today a {
    margin-top: 50px;
    line-height: 45px; }

.about-page .employee {
  margin-bottom: 30px;
  text-align: center; }
  .about-page .employee img {
    background: #eee;
    width: 75%;
    border-radius: 100%;
    border: 10px solid #eee; }
  .about-page .employee div.name {
    margin-top: 10px;
    font-size: 18px;
    font-weight: bold; }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .about-page .info-about-us:before {
    bottom: 0%;
    border: 0px; }
  .about-page .info-about-us {
    padding-top: 70px; } }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .about-page .banner-content {
    min-height: 560px; }
    .about-page .banner-content img {
      top: 0; }
  .about-page .info-about-us {
    padding-top: 0px; } }

@media only screen and (min-device-width: 375px) and (max-device-width: 667px) {
  .about-page .banner-content {
    visibility: hidden;
    min-height: 100px; }
  .about-page .info-about-us:before {
    bottom: 0%;
    border: 0px; }
  .about-page .info-about-us h1 {
    margin: 0px; }
  .about-page .info-about-us p {
    padding: 15px; } }

/* Footer */
#footer-left {
  text-align: left; }

.lower-footer .social-icons {
  display: inline-block;
  width: 100%; }
  .lower-footer .social-icons a:first-of-type {
    margin-left: 0; }
  .lower-footer .social-icons a:last-of-type {
    margin-right: 0; }

/* Dashboard */
#dashboard ul {
  padding-left: 0px; }

#dashboard img {
  max-width: 100%; }

@media (min-width: 960px) {
  #dashboard ul {
    padding-left: 0px;
    margin-bottom: 30px;
    margin-top: 30px; }
    #dashboard ul li {
      background: url(../../images/checked.png) no-repeat left 0;
      padding-left: 40px;
      list-style: none;
      margin: 0;
      color: #666;
      font-family: PTSans;
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 20px; } }

/* Features page */
.features-page .banner-content {
  min-height: 300px;
  position: relative;
  background: radial-gradient(at bottom right, #56a7f9, #1180f4); }
  .features-page .banner-content .container {
    padding-top: 100px;
    color: #FFF; }
    .features-page .banner-content .container p {
      color: #fff; }
    .features-page .banner-content .container img {
      max-width: 100%; }
    .features-page .banner-content .container h3 {
      font-family: WorkSans;
      font-size: 40px;
      font-weight: 200;
      letter-spacing: -1px;
      margin-bottom: 50px;
      text-align: center; }
    .features-page .banner-content .container small {
      padding-top: 20px;
      font-family: PTSans;
      font-size: 25px;
      font-weight: 200;
      display: block;
      color: #FFF; }

.features-page #features ul {
  padding-left: 0; }
  .features-page #features ul li {
    line-height: 22px;
    padding-left: 0;
    list-style: none;
    margin: 0;
    margin-top: 5px; }

#counters span {
  font-size: 44px;
  display: inline-block; }
  #counters span:not(:first-child) {
    margin-left: 5px; }
  #counters span.digit {
    padding: 10px 20px;
    background: #1080f2;
    color: #fff;
    border-radius: 3px;
    opacity: 0; }
    #counters span.digit.one {
      animation-delay: 0.4s; }
    #counters span.digit.two {
      animation-delay: 0.5s; }
    #counters span.digit.three {
      animation-delay: 0.6s; }
    #counters span.digit.four {
      animation-delay: 0.7s; }
    #counters span.digit.five {
      animation-delay: 0.8s; }
    #counters span.digit.six {
      animation-delay: 0.9s; }
    #counters span.digit.seven {
      animation-delay: 1.0s; }
    #counters span.digit.eight {
      animation-delay: 1.1s; }

#counters div.comma {
  font-size: 44px;
  width: 20px;
  color: #1080f2;
  display: inline-block; }

#counters.show span.digit {
  animation: fade-down 1500ms;
  animation-fill-mode: both; }

@media screen and (max-width: 970px) {
  #counters span {
    font-size: 32px; }
    #counters span.digit {
      padding: 10px 15px; }
  #counters div.comma {
    font-size: 32px;
    width: 10px; } }

@media screen and (max-width: 767px) {
  #counters span {
    font-size: 26px; }
    #counters span.digit {
      padding: 10px 10px; }
  #counters div.comma {
    font-size: 26px;
    width: 10px; } }

@keyframes fade-down {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Subscription plan */
@media screen and (max-width: 970px) {
  .subscription-plans {
    overflow-x: auto; }
    .subscription-plans .subscription-plans-scroller {
      width: 1230px; }
    .subscription-plans .plan-container {
      min-width: 200px; } }

@media screen and (max-width: 970px), screen and (min-width: 1200px) {
  .subscription-plans .subscription-plans-scroller > :nth-child(1) .subscription-plan h4 {
    margin-top: 25px;
    border-top: 5px solid #1080f2; }
  .subscription-plans .subscription-plans-scroller > :nth-child(2) .subscription-plan h4 {
    margin-top: 20px;
    border-top: 10px solid #1080f2; }
  .subscription-plans .subscription-plans-scroller > :nth-child(3) .subscription-plan h4 {
    margin-top: 15px;
    border-top: 15px solid #1080f2; }
  .subscription-plans .subscription-plans-scroller > :nth-child(4) .subscription-plan h4 {
    margin-top: 10px;
    border-top: 20px solid #1080f2; }
  .subscription-plans .subscription-plans-scroller > :nth-child(5) .subscription-plan h4 {
    margin-top: 5px;
    border-top: 25px solid #1080f2; }
  .subscription-plans .subscription-plans-scroller > :nth-child(6) .subscription-plan h4 {
    margin-top: 0px;
    border-top: 30px solid #1080f2; } }

.subscription-plans .subscription-plan {
  background: #fff;
  margin: 0 -15px; }
  .subscription-plans .subscription-plan h4 {
    text-align: center;
    background: #1080f2;
    color: #fff;
    padding: 15px 10px;
    margin: 0;
    font-size: 20px; }
  .subscription-plans .subscription-plan ul {
    list-style: none;
    padding: 0;
    text-align: center;
    margin: 0; }
    .subscription-plans .subscription-plan ul li {
      height: 50px;
      border-left: 1px solid #e5e5e5;
      border-right: 1px solid #e5e5e5;
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 0 10px;
      overflow: hidden; }
      .subscription-plans .subscription-plan ul li:nth-child(odd) {
        background: rgba(230, 230, 230, 0.5); }
      .subscription-plans .subscription-plan ul li.plan-price {
        font-size: 20px;
        color: #1080f2; }
        .subscription-plans .subscription-plan ul li.plan-price span.interval {
          font-size: 16px;
          color: #333; }
      .subscription-plans .subscription-plan ul li.action {
        height: 75px; }
      .subscription-plans .subscription-plan ul li:last-child {
        border-bottom: 1px solid #e5e5e5; }

.content-page .header {
  padding-top: 0;
  padding-bottom: 50px;
  text-align: center; }

.content-page h2 {
  font-size: 25px; }

.content-page section {
  padding: 20px; }

.content-page img {
  margin-top: 35px;
  max-width: 100%; }

.content-page .boxed {
  background: #f8f8f8; }
  .content-page .boxed:after {
    content: "";
    display: table;
    clear: both; }

.content-page p, .content-page ul {
  font-size: 16px;
  color: #666; }

.breadcrumb.under-header {
  margin-top: 64px; }

.breadcrumb a {
  margin-right: 26px; }
  .breadcrumb a:after {
    content: '»';
    margin: 0 10px;
    color: #333;
    position: absolute;
    pointer-events: none; }
  .breadcrumb a:last-child:after {
    display: none; }

#google-maps {
  position: relative;
  padding-bottom: 30%;
  height: 0;
  overflow: hidden; }
  #google-maps iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important; }

section.block {
  margin-bottom: 10px;
  padding: 25px; }
  section.block img {
    max-width: 100%; }
  section.block:first-child {
    padding-top: 0; }
  section.block:last-child {
    margin-bottom: 100px; }
  section.block[data-type="header-block"], section.block[data-type="header-image-block"] {
    text-align: center; }
    section.block[data-type="header-block"] h1, section.block[data-type="header-image-block"] h1 {
      font-size: 36px; }
    section.block[data-type="header-block"] img, section.block[data-type="header-image-block"] img {
      margin-top: 35px; }
  section.block[data-type="content-image-block"].boxed, section.block[data-type="content-block"].boxed {
    background: #f8f8f8; }
    section.block[data-type="content-image-block"].boxed:after, section.block[data-type="content-block"].boxed:after {
      content: "";
      display: table;
      clear: both; }
  section.block[data-type="content-image-block"] h2, section.block[data-type="content-block"] h2 {
    font-size: 25px;
    margin-top: 0; }
  section.block[data-type="content-image-block"] p, section.block[data-type="content-block"] p {
    margin-bottom: 10px; }
  section.block[data-type="content-image-block"] .img-container, section.block[data-type="content-block"] .img-container {
    display: flex;
    justify-content: center;
    align-items: center; }
  section.block[data-type="content-image-block"] .image-col, section.block[data-type="content-image-block"] .text-col, section.block[data-type="content-block"] .image-col, section.block[data-type="content-block"] .text-col {
    flex-grow: 1; }
  section.block[data-type="content-image-block"] .row, section.block[data-type="content-block"] .row {
    display: flex;
    flex-wrap: wrap;
    margin: 0; }
  section.block[data-type="content-image-block"]:nth-child(odd) .row .image-col, section.block[data-type="content-block"]:nth-child(odd) .row .image-col {
    order: 1; }
