/* email widget */
.visible {
    display: block;
}

#text-2 {
    position: fixed;
    right: 40px;
    bottom: 0;
    z-index: 999995;
    width: 300px;
    border-radius: 3px 3px 0px 0px;
    padding: 0;
    overflow: hidden;
}

.widget {
    float: left;
    margin: 0;
    padding: 0 0 36px;
    width: 100%;
}

#text-2 h3 {
    background-color: #FF5600;
    color: #ffffff;
    font-size: 16px !important;
    height: 40px;
    margin: 0;
    font-family: 'Open Sans', sans-serif;
    padding: 0 22px;
    line-height: 40px;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    transition: all 300ms linear;
    cursor: pointer;
}

#text-2 h3:hover {
    background-color: #273140;
}

#text-2 .textwidget {
    background: #ffffff;
    padding: 22px;
    border: 1px solid #FF5600;
    border-top: 0;
    border-bottom: 0;
    overflow: hidden;
    display: none;
}


#text-2 .error {
    color: #F44336;
}

#text-2 .success-msg {
    background: #ffffff;
    padding: 22px;
    border: 1px solid #FF5600;
    border-top: 0;
    border-bottom: 0;
    overflow: hidden;
    display: none;
}

.textwidget {
    float: left;
    width: 100%;
    line-height: 28px;
}

.widget form {
    float: left;
    width: 100%;
    background: transparent;
    border: 0;
    font-size: 100%;
    margin: 0;
    outline: 0;
    padding: 0;
    vertical-align: baseline;
}

#text-2 label{
    font-size: 10px !important;
    color: #86999b;
    background: transparent;
    border: 0;
    margin: 0;
    outline: 0;
    padding: 0;
    vertical-align: baseline;
}

#text-2 input[type=text], #text-2 input[type=email], #text-2 textarea {
    border: none;
    color: #333;
    margin-bottom: 15px;
    margin-top: 0px;
    background: rgba(0,0,0,.05);
    padding: 12px 12px;
    display: block;
    font-size: 14px;
    width: 100%;
}

#close-signup-form {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    height: 22px;
    width: 22px;
    line-height: 22px;
    text-align: center;
    border-radius: 50%;
    background-color: #000000;
    color: #ffffff;
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    -ms-transition: all 200ms linear;
    -o-transition: all 200ms linear;
    transition: all 200ms linear;
    top: 10px;
    right: 10px;
}

#text-2.open #close-signup-form {
    opacity: 0.5;
    visibility: visible;
}

#mobile-mc-text {
    display: none;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-size: 9px;
    border-radius: 1px;
    color: #ffffff !important;
    background-color: #2ecc71;
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    -ms-transition: all 200ms linear;
    -o-transition: all 200ms linear;
    transition: all 200ms linear;
    cursor: pointer;
}

#text-2 input[type=submit], button, input[type=button] {
    background-color: #1080F2;
    color:#fff;
    border: none;
    text-transform: uppercase;
    margin: 10px 0px 0px;
    font-size: 14px;
    padding: 11px 20px;
    float: right;
    cursor: pointer;
    font-weight: 600;
    -webkit-appearance: none;
    width: 100%;
}