@media(min-width: 769px) {
    #kb-header {
        height: 550px;
    }
}

@media(max-width: 768px) {
    #kb-header {
        height: 425px;
    }
}

@media(min-width: 962px) {
    input[type=submit] {
        background: #fff;
        border-color: #1080f2;
        font-weight: 400;
        color: #1080f2;
        margin-bottom: 15px;
        border: 5px solid;
    }

    input[type=submit]:hover {
        background-color: #eee;
    }
}

@media(max-width: 961px) {
    input[type=submit] {
        background: #fff;
        border-color: #1080f2;
        font-weight: 400;
        color: #1080f2;
        margin-bottom: 15px;
        margin-top: 4px;
        padding: 15px 20px 17px !important;
        border-left: 5px solid;
        border-top: none;
        border-bottom: none;
        border-right: none;
    }

    input[type=submit]:hover {
        background-color: #eee;
    }
}

a, a:hover, a:active, a:visited {
    text-decoration: none!important;
}

.margin-btm-50 {
    margin-bottom: 50px !important;
}

.margin-top-btm-50 {
    margin-top: 50px;
    margin-bottom: 50px;
}

#kb-header #particles-js {
    height: 550px;
}

.global-search {
    text-align: center;
    padding-top: 5px;
}

form.searchform i.livesearch {
    position: absolute;
    top: 36px;
    left: 29px;
    font-size: 20px;
    animation: bounceIn 750ms linear infinite alternate;
    -moz-animation: bounceIn 750ms linear infinite alternate;
    -webkit-animation: bounceIn 750ms linear infinite alternate;
    -o-animation: bounceIn 750ms linear infinite alternate;
    color: #A8A8A8 !important;
    padding-left: 1px;
    padding-right: 1px;
}

.form-group input.header-search {
    padding-left: 42px;
}

.form-control.header-search {
    display: block;
    box-shadow: inset 0 0 0 0 transparent;
    width: 100%!important;
    float: left;
    font-weight: 600;
    letter-spacing: 1px;
    color: #B5B5B5;
    display: inline-block;
    min-height: 52px;
}

textarea, select, input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="email"], input[type="month"], input[type="number"], input[type="password"], input[type="search"], input[type="tel"], input[type="text"], input[type="time"], input[type="url"], input[type="week"], textarea.form-control {
    background: #edeff2;
    border: none;
    color: #818181;
    outline: 0;
    text-align: left;
    vertical-align: top;
    border: 0;
    border-radius: 4px;
    width: auto;
    padding: 8px 12px;
    font-weight: 400;
    margin: 0 0 10px 0;
    background-color: #fff;
    box-shadow: none!important;
    border: 1px solid #E9E9E9;
    min-height: 45px;
}

select.search-expand-types {
    margin-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    vertical-align: top;
    position: absolute;
    top: 22px;
    right: 124px;
    border-right: 0px;
    border-bottom: 0px;
    border-top: 0px;
    padding: 14px 0px 13px 7px;
    font-weight: 600;
    text-transform: capitalize;
    display: block;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    height: 48px;
    -moz-appearance: none;
    -webkit-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
    background: url('img/uparrow.jpg') no-repeat 95% center #F6F6F6;
    width: 145px;
}

.button-custom {
    position: absolute;
    top: 15px;
    right: 15px;
    padding: 15px 20px 16px !important;
    font-size: 15px;
    font-weight: 600!important;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.trending-search {
    float: left;
    font-size: 13px;
    margin-top: -3px;
    margin-left: 3px;
}

.trending-search span.popular-keyword-title {
    color: #D6D4D4;
}

.trending-search span.popular-keyword-title {
    color: #989CA6;
    font-weight: 600;
    letter-spacing: 0.4px;
    margin-right: 5px;
}

.trending-search a {
    color: #BFBFBF!important;
}

.trending-search a {
    letter-spacing: 0.2px;
    color: #B5B5B5!important;
    margin-left: 6px;
}

.custom-well {
    min-height: 20px;
    padding: 30px 19px 19px 19px;
    margin-bottom: 20px;
    background: #f5f5f5;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    height: auto;
}

.sidebar-widget {
    margin-bottom: 42px;
    padding: 0px 15px;
}

.display-section {
    margin-bottom: 30px;
}

h4.widget-title.widget-custom {
    margin-bottom: 20px;
}

.sidebar-widget h5.widget-title, .body-content .sidebar-widget h5 {
    display: inline-block;
    padding: 0px;
    text-transform: none;
    font-family: inherit;
    letter-spacing: 0.5px;
    margin-bottom: 20px;
}

.sidebar-widget.widget_kb_cat_widget ul {
    padding-left: 0px;
}

.sidebar-widget ul li {
    list-style-type: none!important;
    margin-bottom: 10px!important;
    list-style: none!important;
    letter-spacing: 0.3px;
}

.sidebar-nav ul li a {
    color: #333;
}

.display-faq-section ul li a {
    letter-spacing: 0.3px;
    text-transform: capitalize;
}

.kb-service-heading {
    display: inline-block;
    margin-left: 5px;
    margin-bottom: 0;
}

.nc-icon-outline {
    color: #1080f2;
    margin-bottom: 0;
    font-size: 22px;
}

.category-list a{
    display:inline-block;
    font-size: 16px;
    color: #000;
}

.category-list li {
    list-style: none;
    padding: 8px 0px 8px 10px;
}

.category-list li:before
{
    padding-top: 5px;
    content: "\f0f6";
    display: inline-block;
    font: normal normal normal 16px/1 FontAwesome;
    font-size: 16px;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-right: 10px;
    position: absolute;
    left:35px;
}

.category-list li:hover {
    font-weight: 600;
}

.category-list li:hover:before {
    color: #1080f2;
}

.category-overview > div {
    margin-bottom: 50px;
}

.kb-box-single {
    padding: 20px 10% 8px 62px;
    margin-bottom: 10px;
    background-color: #FFF;
    border-bottom: 1px solid #efefef;
    color: #C4C4C4;
}

.kb-box-single:before {
    content: "\f0f6";
    display: inline-block;
    font: normal normal normal 16px/1 FontAwesome;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    font-size: 45px;
    color: #888B8A;
    padding-top: 25px;
    left: 15px;
}

.kb-box-single:hover:before {
    color: #1080f2;
}

.kb-box-single > h2 {
    padding-top: 4px;
    margin-bottom: 5px;
}

.kb-box-single > p {
    margin-left: 10px;
}

.knowledgebase-cat-body h2 a {
    outline: 0!important;
    color: #4d515c!important;
    text-decoration: none!important;
    padding-top: 20px;
}

.knowledgebase-cat-body h2 a:hover {
    color: #000000!important;
    font-weight: 600;
}

.kb-box-single span {
    color: #A9A9A9;
    padding-left: 2px;
    padding-right: 20px;
    font-size: 13px;
}

.ui-menu {
    width: 940px !important;
}

.ui-menu .ui-menu-item {
    background-repeat: no-repeat;
    padding: 10px 15px 10px 15px;
    display: block;
    color: #595959;
    font-weight: 600;
    font-size: 16px;
}

.ui-menu-item .ui-state-focus li {
    background-color: #fafafa;
}