// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

$width: 200px;

// Added by request from Martin Sølberg
.schema_seo {
    position: absolute;
    color: transparent;
}

/* About us */
.about-page {

    .banner-content {
        min-height: 300px;
        position: relative;

        img {
            max-width: 100%;
            position: relative;
            top: -9vw;
            width: 100%;
        }

        .container {
            position: absolute;
            top: 25%;
            width: 100%;
            text-align:center;
            color: #FFF;

            h3 {
                font-family: WorkSans;
                font-size: 40px;
                font-weight: 200;
                letter-spacing: -1px;
            }


            small {
                padding-top: 20px;
                font-family: PTSans;
                font-size: 25px;
                font-weight: 200;
                display: block;
                color: #FFF;
            }
        }
    }


    .info-about-us:before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 100%;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 80px 100vw 0 0;
        border-color: transparent #fff transparent transparent;
        border-width: 180px 100vw 0 0;
    }

    .info-about-us {
        background-color: #fff;
        margin-top: -17vw;
        text-align: center;
        padding-bottom: 15px;
        position: relative;
        z-index: 99;

        h2 {
            font-size: 40px;
            line-height: 48px;
            margin-bottom: 10px;
        }

        p {
            max-width: 600px;
            margin: 0 auto;
            font-size: 18px;
            line-height: 30px;
            padding-bottom: 32px;
        }
    }

    .info-why,
    .info-get-today,
    .info-dashboard,
    .employees {
        .container {
            padding: 70px 0px;
        }
        .section-heading{
            text-align: center;
        }
        .separator{
            margin-bottom: 25px;
        }

        h2 {
            margin-top: 0;
            margin-bottom: 18px;
            font-size: 30px;
            letter-spacing: -1px;
            line-height: 40px;
        }

        p {
            color: #666;
            font-family: PTSans;
            font-size: 16px;
            font-weight: 400;
            line-height: 30px;
        }
    }

    .info-why, .employees {
        background: #F8F8F8;
    }

    .location {
        .container {
            padding: 35px 0;
        }
        p{
            margin-bottom: 15px;
        }
    }

    .info-get-today {
        background: #F8F8F8;

        a {
            margin-top: 50px;
            line-height: 45px;
        }
    }

    .employee{
        margin-bottom: 30px;
        text-align: center;

        img{
            background: #eee;
            width: 75%;
            border-radius: 100%;
            border: 10px solid #eee;
        }
        div.name{
            margin-top: 10px;
            font-size: 18px;
            font-weight: bold;
        }
    }
}

@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)  {
    .about-page {
        .info-about-us:before {
            bottom: 0%;
            border: 0px;
        }

        .info-about-us {
            padding-top: 70px;
        }
    }
}

@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : portrait) {
    .about-page {
        .banner-content{
            min-height: 560px;

            img {
                top: 0;
            }
        }

        .info-about-us {
            padding-top: 0px;
        }
    }
}

@media only screen
and (min-device-width : 375px)
and (max-device-width : 667px) {
    .about-page {
        .banner-content{
            visibility: hidden;
            min-height: 100px;
        }

        .info-about-us:before {
            bottom: 0%;
            border: 0px;
        }

        .info-about-us {
            h1{
                margin: 0px;
            }

            p {
                padding: 15px;
            }
        }
    }
}

/* Footer */
#footer-left {
    text-align: left;
}

.lower-footer {
    .social-icons {
        display: inline-block;
        width: 100%;

        a:first-of-type {
            margin-left: 0;
        }
        a:last-of-type{
            margin-right: 0;
        }
    }
}

/* Dashboard */

#dashboard {
    ul {
        padding-left: 0px;
    }
    img{
        max-width: 100%;
    }
}
@media (min-width: 960px) {
    #dashboard {
        ul {
            padding-left: 0px;
            margin-bottom: 30px;
            margin-top: 30px;

            li {
                background: url(../../images/checked.png) no-repeat left 0;
                padding-left: 40px;
                list-style: none;
                margin: 0;
                color: #666;
                font-family: PTSans;
                font-size: 16px;
                font-weight: 400;
                margin-bottom: 20px;
            }
        }
    }
}

/* Features page */
.features-page {

    .banner-content {
        min-height: 300px;
        position: relative;
        background: radial-gradient(at bottom right, #56a7f9, #1180f4);

        .container {
            padding-top: 100px;
            color: #FFF;

            p{
                color: #fff;
            }
            img{
                max-width: 100%;
            }

            h3 {
                font-family: WorkSans;
                font-size: 40px;
                font-weight: 200;
                letter-spacing: -1px;
                margin-bottom: 50px;
                text-align:center;
            }


            small {
                padding-top: 20px;
                font-family: PTSans;
                font-size: 25px;
                font-weight: 200;
                display: block;
                color: #FFF;
            }
        }
    }


    #features{

        ul {
            padding-left: 0;

            li {
                //background: url(../../images/checked.png) no-repeat left 0;
                line-height: 22px;
                padding-left: 0;
                list-style: none;
                margin: 0;
                margin-top: 5px;
            }
        }
    }
}

#counters{

    span{
        font-size: 44px;
        display: inline-block;

        &:not(:first-child)
        {
            margin-left: 5px;
        }

        &.digit {
            padding: 10px 20px;
            background: #1080f2;
            color: #fff;
            border-radius: 3px;
            opacity: 0;

            &.one   { animation-delay: 0.4s; }
            &.two   { animation-delay: 0.5s; }
            &.three { animation-delay: 0.6s; }
            &.four  { animation-delay: 0.7s; }
            &.five  { animation-delay: 0.8s; }
            &.six   { animation-delay: 0.9s; }
            &.seven { animation-delay: 1.0s; }
            &.eight { animation-delay: 1.1s; }
        }
    }

    div.comma {
        font-size: 44px;
        width: 20px;
        color: #1080f2;
        display: inline-block;
    }

    &.show{
        span.digit{
            animation: fade-down 1500ms;
            animation-fill-mode: both;
        }
    }

    @media screen and (max-width: 970px) {

        span {
            font-size: 32px;

            &.digit {
                padding: 10px 15px;
            }
        }
        div.comma {
            font-size: 32px;
            width: 10px;
        }
    }

    @media screen and (max-width: 767px) {

        span {
            font-size: 26px;

            &.digit {
                padding: 10px 10px;
            }
        }
        div.comma {
            font-size: 26px;
            width: 10px;
        }
    }
}

@keyframes fade-down {
    0% { opacity: 0; }
    100% {opacity: 1; }
}

/* Subscription plan */
.subscription-plans {

    @media screen and (max-width: 970px) {
        overflow-x: auto;

        .subscription-plans-scroller{
            width: $width*6+30px;
        }

        .plan-container{
            min-width: $width;
        }
    }

    @media screen and (max-width: 970px), screen and (min-width: 1200px) {
        @for $i from 1 to 7 {
            .subscription-plans-scroller > :nth-child(#{$i}) .subscription-plan h4 {
                margin-top: 5px*(6-$i);
                border-top: 5px*$i solid #1080f2;
            }
        }
    }

    .subscription-plan {
        background: #fff;
        margin: 0 -15px;

        h4 {
            text-align: center;
            background: #1080f2;
            color: #fff;
            padding: 15px 10px;
            margin: 0;
            font-size: 20px;
        }

        ul {
            list-style: none;
            padding: 0;
            text-align: center;
            margin: 0;

            li {
                height: 50px;
                border-left: 1px solid #e5e5e5;
                border-right: 1px solid #e5e5e5;
                display: flex;
                justify-content: center;
                flex-direction: column;
                padding: 0 10px;
                overflow: hidden;

                &:nth-child(odd) {
                    background: rgba(230,230,230,.5);
                }

                &.plan-price {
                    font-size: 20px;
                    color: #1080f2;

                    span.interval{
                        font-size: 16px;
                        color: #333;
                    }
                }
                &.action{
                    height: 75px;
                }

                &:last-child{
                    border-bottom: 1px solid #e5e5e5;
                }
            }
        }
    }
}

.content-page{

    .header{
        padding-top: 0;
        padding-bottom: 50px;
        text-align: center;
    }

    h2{
        font-size: 25px;
    }

    section{
        padding: 20px;
    }

    img{
        margin-top: 35px;
        max-width: 100%;
    }

    .boxed{
        background: #f8f8f8;

        &:after {
            content: "";
            display: table;
            clear: both;
        }
    }

    p, ul{
        font-size: 16px;
        color: #666;
    }
}

.breadcrumb{

    &.under-header{
        margin-top: 64px;
    }

    a{
        margin-right: 26px;

        &:after{
            content: '»';
            margin: 0 10px;
            color: #333;
            position: absolute;
            pointer-events: none;
        }
        &:last-child:after {
            display: none;
        }
    }
}

#google-maps{
    position: relative;
    padding-bottom: 30%;
    height: 0;
    overflow: hidden;

    iframe{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%!important;
        height: 100%!important;
    }
}

section.block{
    margin-bottom: 10px;
    padding: 25px;

    img{
        max-width: 100%;
    }

    &:first-child{
        padding-top: 0;
    }

    &:last-child{
        margin-bottom: 100px;
    }


    &[data-type="header-block"], &[data-type="header-image-block"]{
        text-align: center;

        h1{
            font-size: 36px;
        }
        img{
            margin-top: 35px;
        }
    }

    &[data-type="content-image-block"], &[data-type="content-block"]{

        &.boxed{
            background: #f8f8f8;

            &:after {
                content: "";
                display: table;
                clear: both;
            }
        }

        h2{
            font-size: 25px;
            margin-top: 0;
        }

        p{
            margin-bottom: 10px;
        }

        .img-container{
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .image-col, .text-col{
            flex-grow: 1;
        }

        .row{
            display: flex;
            flex-wrap: wrap;
            margin: 0;
        }

        &:nth-child(odd){
            .row .image-col{
                order: 1;
            }
        }
    }

}